import Alpine from "../_snowpack/pkg/alpinejs.js";
import moment from "../_snowpack/pkg/moment.js";
import Cookies from '../_snowpack/pkg/js-cookie.js'

window.Alpine = Alpine;
Alpine.data('main', () => ({
    is_queue_cam_available: true,
    walk_in_banner: false,
    current_day: moment().weekday(),
    bankHolidayDates: null,
    opening_times: {
        1: '09:00', //Monday
        2: '09:00', //Tuesday
        3: '09:00', //Wednesday
        4: '09:00', //Thursday
        5: '09:00', //Friday
        6: '08:30', //Saturday
    },
    closing_times: {
        1: '18:30', //Monday
        2: '18:30', //Tuesday
        3: '18:30', //Wednesday
        4: '19:00', //Thursday
        5: '19:00', //Friday
        6: '13:30', //Saturday
    },
    is_bank_holiday: false,
    opens_today_at: null,
    closes_today_at: null,
    last_entry_today_at: null,
    is_currently_open: null,
    closing_in: null,
    nearly_closed: null,
    async getBankHolidays() {
        let response = await fetch('https://www.gov.uk/bank-holidays.json');
        return await response.json();
    },
    bankHolidayList(dates, countries) {
        const dateList = countries.map(country => dates[country].events.map(event => event.date));
        return dateList;
    },
    setRemaining() {
        var duration = moment.duration(this.last_entry_today_at.diff(moment()));
        this.closing_in = duration.minutes() + " minutes";
        this.nearly_closed = duration.hours() < 1;
    },
    closeBanner() {
        this.walk_in_banner = false;
        Cookies.set('walk_in_banner', true, { expires: 365 });
    },
    async init() {
        //GET BANK HOLIDAY LIST FROM GOV SITE
        this.bankHolidayDates = await this.getBankHolidays();
        this.bankHolidayDates = this.bankHolidayList(this.bankHolidayDates, ['england-and-wales'])[0];

        //CHECK IF TODAY IS A BANK HOLIDAY
        this.is_bank_holiday = this.bankHolidayDates.includes(moment().format('YYYY-MM-DD'));

        this.walk_in_banner = !Cookies.get('walk_in_banner');
        this.opens_today_at = moment(this.opening_times[this.current_day], 'hh:mm');
        this.closes_today_at = moment(this.closing_times[this.current_day], 'hh:mm');
        this.last_entry_today_at = moment(this.closing_times[this.current_day], 'hh:mm').subtract(30, 'minutes');
        this.is_currently_open = moment().isBetween(this.opens_today_at, this.last_entry_today_at);
        if (this.is_currently_open) {
            this.setRemaining();
            setInterval(() => {
                this.setRemaining();
            }, 1000);
        }
    }
}))

Alpine.start();